import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Hizmetlerimiz | Ace Serve Tenis Kulübü'ndeki Hizmetlerimiz
			</title>
			<meta name={"description"} content={"Ace Serve Tenis Kulübü'nde tenis yolculuğunuzun tüm yönlerine hitap ediyoruz. Hizmet yelpazemiz, oyun deneyiminizi geliştirmek, becerilerinizi geliştirmek ve canlı bir tenis topluluğunu teşvik etmek için özenle tasarlanmıştır.\n"} />
			<meta property={"og:title"} content={"Hizmetlerimiz | Ace Serve Tenis Kulübü'ndeki Hizmetlerimiz"} />
			<meta property={"og:description"} content={"Ace Serve Tenis Kulübü'nde tenis yolculuğunuzun tüm yönlerine hitap ediyoruz. Hizmet yelpazemiz, oyun deneyiminizi geliştirmek, becerilerinizi geliştirmek ve canlı bir tenis topluluğunu teşvik etmek için özenle tasarlanmıştır.\n"} />
			<meta property={"og:image"} content={"https://ujimabrand.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ujimabrand.com/img/favicon.jpeg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ujimabrand.com/img/favicon.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ujimabrand.com/img/favicon.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ujimabrand.com/img/favicon.jpeg"} />
			<link rel={"apple-touch-startup-image"} href={"https://ujimabrand.com/img/favicon.jpeg"} />
			<meta name={"msapplication-TileImage"} content={"https://ujimabrand.com/img/favicon.jpeg"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://ujimabrand.com/img/6.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="460px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h4"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					Tekliflerimize Bir Bakış
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
					Hizmetlerimiz
				</Text>
				<Text font="--base">
					Ace Serve Tenis Kulübü'nde tenis yolculuğunuzun tüm yönlerine hitap ediyoruz. Hizmet yelpazemiz, oyun deneyiminizi geliştirmek, becerilerinizi geliştirmek ve canlı bir tenis topluluğunu teşvik etmek için özenle tasarlanmıştır.
				</Text>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-1">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="end"
				grid-gap="0 80px"
				lg-grid-gap="0 50px"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px" md-margin="0px 0px 50px 0px">
					<Text margin="0px 0px 20px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
						Seçilmiş Hizmetlerimiz Şunları İçerir
					</Text>
					<Text margin="0px 0px 70px 0px" font="--lead" lg-margin="0px 0px 40px 0px" color="#626970">
						Profesyonel Koçluk: Teknik, strateji ve kondisyona odaklanarak her seviye için kişiselleştirilmiş eğitim sunan deneyimli koçlar.
					</Text>
					<Image
						src="https://ujimabrand.com/img/7.jpg"
						display="block"
						width="100%"
						height="600px"
						object-fit="cover"
						border-radius="15px"
						sm-height="400px"
					/>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							- Gençlik Gelişim Programları: Tenis temellerini tanıtmayı ve geleceğin şampiyonlarını yetiştirmeyi amaçlayan, genç meraklılar için özel olarak tasarlanmış kurslar.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							- İleri Seviye Oyuncu Klinikleri: Tecrübeli oyuncular için bu klinikler ileri teknikler, zihinsel dayanıklılık ve rekabet stratejilerine odaklanmaktadır.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							- Sosyal Karışımlar ve Etkinlikler: Kulüp etkinliklerimiz, sosyal buluşmalarımız ve dostluk maçlarımız aracılığıyla diğer tenis severlerle etkileşime geçin.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							- Kort Kiralama: Üyelerin antrenman seansları veya gündelik oyun için rezervasyon yaptırabilecekleri, bakımlı kapalı ve açık kortlara erişim.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							- Ekipman Pro Mağazası: En yeni tenis malzemeleri, profesyonel raket dizimi ve kişiselleştirilmiş ekipman tavsiyeleri sunan tam hizmetli bir mağaza.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							- Üye Salonu ve Tesisler: Rahatlama ve etkileşim için olanaklarla donatılmış konforlu üye salonumuzda dinlenin ve sosyalleşin.
						</Text>
					</Box>
					<Button
						background="rgba(0, 119, 204, 0)"
						padding="0 0 0 0"
						color="--primary"
						font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
						href="/contact"
						type="link"
						text-decoration-line="initial"
					>
						Tüm fırsatları görmek için bizimle iletişime geçin
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});